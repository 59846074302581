import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState, IPromptsState } from './PromptsState';

export interface IPrompts {
    id: number;
    title: string;
    value: string;
    description: string;
    tags: string[];
}

export interface IPromptsResponse {
    prompts: IPrompts[];
}

export interface PromptsState {
    promptList: IPromptsState;
}

export const promptsSlice = createSlice({
    name: 'prompts',
    initialState: { promptList: initialState } as PromptsState,
    reducers: {
        setPrompts: (state, action: PayloadAction<IPromptsResponse | undefined>) => {
            if (!action.payload) {
                state.promptList = {
                    prompts: [],
                };
                return;
            }

            const { prompts } = action.payload;
            state.promptList = { prompts };
        },

        addToPrompts: (state, action: PayloadAction<IPromptsResponse | undefined>) => {
            if (!action.payload) return;

            const { prompts } = action.payload;

            state.promptList.prompts = [...state.promptList.prompts, ...prompts];
        },

        cleanGallery: (state) => {
            state.promptList.prompts = [];
        },
    },
});

export const { setPrompts, addToPrompts, cleanGallery } = promptsSlice.actions;

export default promptsSlice.reducer;
