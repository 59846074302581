import React, { useEffect, useState } from 'react';
import { Fade, Alert } from '@mui/material';
import styles from './Alerts.module.css';
import { RootState } from '../../redux/app/store';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { AlertType } from '../../libs/models/AlertType';
import { removeAlert } from '../../redux/features/app/appSlice';

const ErrorIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.6666 7.99967C13.6666 11.1293 11.1295 13.6663 7.99992 13.6663C4.87031 13.6663 2.33325 11.1293 2.33325 7.99967C2.33325 4.87006 4.87031 2.33301 7.99992 2.33301C11.1295 2.33301 13.6666 4.87006 13.6666 7.99967ZM14.6666 7.99967C14.6666 11.6816 11.6818 14.6663 7.99992 14.6663C4.31802 14.6663 1.33325 11.6816 1.33325 7.99967C1.33325 4.31778 4.31802 1.33301 7.99992 1.33301C11.6818 1.33301 14.6666 4.31778 14.6666 7.99967ZM7.5 10.6663V11.333H8.5V10.6663H7.5ZM7.5 4.66634V9.33301H8.5V4.66634H7.5Z"
            fill="#DB2327"
        />
    </svg>
);

const InfoIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.6666 7.99967C13.6666 11.1293 11.1295 13.6663 7.99992 13.6663C4.87031 13.6663 2.33325 11.1293 2.33325 7.99967C2.33325 4.87006 4.87031 2.33301 7.99992 2.33301C11.1295 2.33301 13.6666 4.87006 13.6666 7.99967ZM14.6666 7.99967C14.6666 11.6816 11.6818 14.6663 7.99992 14.6663C4.31802 14.6663 1.33325 11.6816 1.33325 7.99967C1.33325 4.31778 4.31802 1.33301 7.99992 1.33301C11.6818 1.33301 14.6666 4.31778 14.6666 7.99967ZM8.25006 5.03293C8.25006 5.35693 8.51106 5.62693 8.84406 5.62693C9.16806 5.62693 9.44706 5.35693 9.44706 5.03293C9.44706 4.69993 9.16806 4.42993 8.84406 4.42993C8.51106 4.42993 8.25006 4.69993 8.25006 5.03293ZM6.52206 8.17393C7.16106 7.35493 7.43106 7.37293 7.60206 7.44493L6.35106 10.4059C6.12606 10.9279 6.20706 11.0899 6.57606 11.0899C7.22406 11.0899 8.28606 10.5049 8.82606 9.62293L8.78106 9.56893C8.34006 10.0099 7.95306 10.1719 7.59306 10.2799L8.65506 7.77793C8.99706 6.96793 8.67306 6.69793 8.30406 6.69793C7.72806 6.69793 7.04406 7.12093 6.45006 8.11993L6.52206 8.17393Z"
            fill="#5564DA"
        />
    </svg>
);

const CloseIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.6465 13.3536L2.64648 3.35359L3.35359 2.64648L13.3536 12.6465L12.6465 13.3536Z"
            fill="#656B80"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.3536 3.35352L3.35359 13.3535L2.64648 12.6464L12.6465 2.64641L13.3536 3.35352Z"
            fill="#656B80"
        />
    </svg>
);

export const Alerts: React.FC = () => {
    const dispatch = useAppDispatch();
    const { alerts } = useAppSelector((state: RootState) => state.app);

    const [currentAlertIndex, setCurrentAlertIndex] = useState<number | null>(null);
    const [exiting, setExiting] = useState(false);

    useEffect(() => {
        if (alerts.length > 0) {
            // Always display the most recent alert
            const newestAlertIndex = alerts.length - 1;
            if (currentAlertIndex !== newestAlertIndex) {
                setCurrentAlertIndex(newestAlertIndex);
            }

            const timeout = setTimeout(() => {
                handleDismiss();
            }, 4000);

            return () => {
                clearTimeout(timeout);
            }; // Cleanup timeout
        }

        setCurrentAlertIndex(null); // Reset index when no alerts remain
        return undefined;
    }, [alerts, currentAlertIndex]);

    const handleDismiss = () => {
        setExiting(true);
        setTimeout(() => {
            if (currentAlertIndex !== null) {
                dispatch(removeAlert(currentAlertIndex)); // Remove the current alert
            }
            setExiting(false);
            setCurrentAlertIndex((prev) => (prev !== null && prev > 0 ? prev - 1 : null));
        }, 300);
    };

    const getAlertStyles = (type: AlertType) => {
        switch (type) {
            case AlertType.Error:
                return { borderColor: '#DB2327' };
            default:
                return { borderColor: '#5564DA' };
        }
    };

    const getAlertIcon = (type: AlertType) => {
        if (type === AlertType.Error) return <ErrorIcon />;
        return <InfoIcon />;
    };

    const getAlertByType = (type: AlertType) => {
        switch (type) {
            case AlertType.Error:
                return 'error';
            case AlertType.Warning:
                return 'warning';
            case AlertType.Success:
                return 'success';
            default:
                return 'info';
        }
    };

    if (alerts.length === 0 || currentAlertIndex === null) {
        return null; // No alerts to show
    }

    const currentAlert = alerts[currentAlertIndex];

    return (
        <div className={styles.root}>
            <Fade in={!exiting} timeout={300}>
                <Alert
                    severity={getAlertByType(currentAlert?.type)}
                    variant="outlined"
                    className={styles.alertContainer}
                    icon={getAlertIcon(currentAlert?.type)}
                    sx={{
                        ...getAlertStyles(currentAlert?.type),
                        color: '#474F6C',
                        textAlign: 'left',
                        '& .MuiAlert-message': {
                            padding: '0',
                        },
                    }}
                    action={
                        <div className={styles.actionItems}>
                            {currentAlert?.onRetry && (
                                <span className={styles.button} onClick={currentAlert.onRetry}>
                                    Retry
                                </span>
                            )}
                            <span className={styles.button} onClick={handleDismiss}>
                                <CloseIcon />
                            </span>
                        </div>
                    }
                >
                    {currentAlert?.message.slice(0, 1000) + (currentAlert?.message.length > 1000 ? '...' : '')}
                </Alert>
            </Fade>
        </div>
    );
};
