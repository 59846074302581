// popoverSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PopoverState {
    popovers: boolean[];
    lastPopoverFinished: boolean;
}

const initialState: PopoverState = {
    popovers: [false, false, false, false, false, false, false, false],
    lastPopoverFinished: localStorage.getItem('lastPopoverFinished2') === 'true',
};

const popoverSlice = createSlice({
    name: 'popover',
    initialState,
    reducers: {
        openPopover: (state, action: PayloadAction<number>) => {
            state.popovers[action.payload] = true;
        },
        closePopover: (state, action: PayloadAction<number>) => {
            state.popovers[action.payload] = false;
        },
        nextPopover: (state, action: PayloadAction<{ current: number }>) => {
            const { current } = action.payload;
            state.popovers[current] = false;
            if (current < state.popovers.length - 1) {
                state.popovers[current + 1] = true;
            }
        },
        skipPopovers: (state) => {
            state.popovers = [false, false, false, false, false, false, false, false];
            state.lastPopoverFinished = true;
            localStorage.setItem('lastPopoverFinished2', 'true');
        },
        finishPopover: (state) => {
            state.popovers = [false, false, false, false, false, false, false, false];
            state.lastPopoverFinished = true;
            localStorage.setItem('lastPopoverFinished2', 'true');
        },
    },
});

export const { openPopover, closePopover, nextPopover, skipPopovers, finishPopover } = popoverSlice.actions;
export default popoverSlice.reducer;
