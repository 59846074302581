import { configureStore } from '@reduxjs/toolkit';
import { AppState } from '../features/app/AppState';
import { ConversationsState } from '../features/conversations/ConversationsState';
import resetStateReducer, { resetApp } from './rootReducer';
import { ImagesState } from '../features/images/ImagesState';
import { PopoverState } from '../features/popoverSlice';
import { PromptsState } from '../features/prompts/promptsSlice';

export const store = configureStore({
    reducer: resetStateReducer,
});

export interface RootState {
    app: AppState;
    conversations: ConversationsState;
    images: ImagesState;
    popover: PopoverState;
    prompts: PromptsState;
}

export const getSelectedChatID = (): string => {
    return store.getState().conversations.selectedId;
};

export type AppDispatch = typeof store.dispatch;

// Function to reset the app state
export const resetState = () => {
    store.dispatch(resetApp());
    localStorage.removeItem('shownBanner');
};
