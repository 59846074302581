import { makeStyles, shorthands } from '@fluentui/react-components';
import { ChatWindow } from '../chat/ChatWindow';
import { ChatList } from '../chat/chat-list/ChatList';
import DrawerMenu from '../DrawerMenu';
import { Breakpoints } from '../../styles';
import FeaturesModal from '../FeaturesModal';
import CodeOfUse from '../chat/code-of-use/CodeOfUse';

const useClasses = makeStyles({
    container: {
        ...shorthands.overflow('auto'),
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        overflowY: 'auto',
    },
    view: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'start',
        height: '100dvh',
        width: '100%',
    },
    chatList: {
        ...Breakpoints.small({
            display: 'none',
        }),
        width: '100%',
        maxWidth: '242px',
        ...shorthands.borderRight('1px', 'solid', '#E0D6C8'),
    },
    drawerMenu: {
        display: 'none',
        ...Breakpoints.small({
            display: 'block',
        }),
    },
});

export const ChatView = () => {
    const classes = useClasses();

    return (
        <div className={classes.container}>
            <CodeOfUse />
            <FeaturesModal />
            <div className={classes.drawerMenu}>
                <DrawerMenu />
            </div>
            <div className={classes.chatList}>
                <ChatList />
            </div>
            <div className={classes.view}>
                <ChatWindow />
            </div>
        </div>
    );
};
